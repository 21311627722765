/* @define Hero */

.Hero {
	background-color: transparent;
}

.Hero-contentContainer {
	box-sizing: border-box;
	padding: var(--u-page-spacing);
}

.Hero-titleProject,
.Hero-titleTagline {
	display: block;
}

.Hero-titleProject {
	color: var(--color-orange);
	font-weight: 600;
	margin-block-end: 1.25rem;
}

.Hero-titleTagline {
	font-size: clamp(2.65em, 4vw, 3.75em);
	font-weight: 500;
	line-height: 1.2;
}

.Hero-title + .Hero-link {
	display: inline-block;
	margin-block-start: 3em;
}

.Hero-link {
	background-color: var(--color-grey-900);
	border: 0.2em solid transparent;
	border-radius: var(--border-radius);
	color: var(--color-white);
	font-size: var(--typo-Default-font-size);

	/* @TODO Setup custom property to handle padding */
	padding: 0.65625rem 1.40625rem; /* 10.5px 22.5px To match factorial's website */
}

@media (max-width: 48em) {
	.Hero-container {
		display: flex;
		flex-direction: column-reverse;
	}

	.Hero-image {
		aspect-ratio: 1.2/1;
		height: auto;
		width: 100%;
	}
}

@media (min-width: 48.0625em) {
	.Hero {
		display: flex;
		justify-content: center;
	}

	:is(.Hero-imageContainer, .Hero-contentContainer):only-child {
		flex: 1;
	}

	:is(.Hero-imageContainer, .Hero-contentContainer):not(:only-child) {
		flex: 0 0 50%;
	}

	/* stylelint-disable-next-line plugin/selector-bem-pattern */
	.Hero-imageContainer picture {
		height: 100%;
	}

	.Hero-image {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}

	.Hero-container {
		display: flex;
		max-width: var(--u-max-width);
		width: 100%;
	}
}
