/* @define Features */

.Features {
	box-sizing: border-box;
	display: grid;
	list-style: none;
	margin-inline: auto;
	max-width: var(--u-max-width);
	padding-inline: var(--u-page-spacing);
}

.Features-title {
	font-size: var(--typo-h3-font-size);
	font-weight: var(--typo-h3-font-weight);
	line-height: var(--typo-h3-line-height);
}

.Features-details {
	line-height: 1.875;
	margin-block-start: 2em;
}

@media (max-width: 58.5em) {
	.Features {
		gap: 3rem;
		padding-block: 3rem;
	}

	.Features-icon + .Features-title {
		margin-block-start: 1em;
	}
}

@media (min-width: 58.5625em) {
	.Features {
		gap: var(--u-page-spacing);
		grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
		padding-block: 6em;
	}

	.Features-icon + .Features-title {
		margin-block-start: 2em;
	}
}
